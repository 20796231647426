import React from 'react';
import Countdown from 'react-countdown';
import './App.css';
import successKid from './assets/success-kid.png';

function App() {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <div className="text-center">
          <p className="text-4xl font-bold">Time's up, check your $ROI!</p>
        </div>
      );
    } else {
      return (
        <div className="text-center space-y-4">
          <div className="flex justify-center space-x-6 text-6xl font-bold">
            <div>
              <p>{days}</p>
              <p className="text-2xl">Days</p>
            </div>
            <div>
              <p>{hours}</p>
              <p className="text-2xl">Hours</p>
            </div>
            <div>
              <p>{minutes}</p>
              <p className="text-2xl">Minutes</p>
            </div>
            <div>
              <p>{seconds}</p>
              <p className="text-2xl">Seconds</p>
            </div>
          </div>
          <p className="text-2xl">until launch...</p>
        </div>
      );
    }
  };

  const endTime = 1686128400 * 1000;

  return (
    <div className="bg-gradient-to-br from-yellow-500 to-orange-500 min-h-screen font-sans text-white flex flex-col items-center justify-center">
      <img
        src={successKid}
        alt="Success Kid"
        className="mx-auto w-64 h-64 animate-pulse"
      />
      <h1 className="text-4xl font-bold mb-6 text-center">Patience creates substantial $ROI</h1>
      <Countdown date={endTime} renderer={renderer} />
    </div>
  );
}

export default App;
